import Link from 'next/link'
import styled from 'styled-components'
import Logo from './Logo.svg'

export default function LogoHeader() {
  return (
    <LogoContainer>
      <LogoLink
        href={{
          pathname: '/'
        }}
        passHref
      >
        <LogoImage src={Logo} alt='Logo' />
      </LogoLink>
    </LogoContainer>
  )
}

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px;
`
const LogoLink = styled(Link)`
  ${({ theme }) => theme.fonts.Bold24};
  color: ${({ theme }) => theme.colors.OnSurface2};
`
const LogoImage = styled.img`
  width: 196px;
  height: 54px;
`
